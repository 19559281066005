import './App.css';
import MPESAImage from '../src/Assets/M-PESA.png';
import { useState } from 'react';
import { Buffer } from 'buffer';

function App() {
  const [mpesanumber, setmpesanumber] = useState("")

  // get access token from safaricom api
async function getAccessToken() {
  const consumerKey = "mnDc7zuptHxG7OG7CQmBgJKKGyJsm5UnJf6RAQwREThA5YL4";
  const consumersecret =
    "Jbp8utg85t50REC6JUMAo0f9BIabiUFrVIiLx3ukWdLZdQT9TxEQYJ2xeHk6zs33";
  const url =
    " https://api.safaricom.co.ke/oauth/v1/generate?grant_type=client_credentials";
  const auth =
    "Basic " +
    Buffer.from(consumerKey + ":" + consumersecret).toString("base64");
  const headers = {
    Authorization: auth,
    "Content-Type": "application/json",
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
}


// lipa na mpesa
async function lipaNaMpesa() {
    const amount= 1
    const targetNumber= "254700600679"
    const receiverNumber= "254700600679"
    const shortCode= "4155554"
    const passKey= "44bb3fbf78c9086a8f501f47ad0bbac913db4316923586a1d06d9bf4165080df"
    const accountReference= "Merchant"
    const transactionDesc= "Payment of Online Goods"
    const callbackUrl= " https://dadc-197-248-160-69.ngrok-free.app "

  const accessToken = await getAccessToken();
  const headers = new Headers();
  headers.append("Content-Type", "application/json");
  headers.append("Authorization", `Bearer ${accessToken.access_token}`);

  const timeStamp = new Date()
    .toISOString()
    .replace(/[^0-9]/g, "")
    .slice(0, -3);
  const password = Buffer.from(shortCode + passKey + timeStamp).toString(
    "base64"
  );

  fetch("https://api.safaricom.co.ke/mpesa/stkpush/v1/processrequest", {
    method: "POST",
    headers,
    body: JSON.stringify({
      BusinessShortCode: shortCode,
      Password: password,
      Timestamp: timeStamp,
      TransactionType: "CustomerPayBillOnline",
      Amount: amount,
      PartyA: receiverNumber,
      PartyB: shortCode,
      PhoneNumber: targetNumber,
      CallBackURL: callbackUrl,
      AccountReference: accountReference,
      TransactionDesc: transactionDesc,
    }),
  })
    .then((response) => response.text())
    .then((result) => console.log(result))
    .catch((error) => console.log(error));
}


// call the function
// lipaNaMpesa({
//   amount: 1,
//   targetNumber: "254793616321",
//   receiverNumber: "254728859865",
//   shortCode: "4155554",
//   passKey: "44bb3fbf78c9086a8f501f47ad0bbac913db4316923586a1d06d9bf4165080df",
//   accountReference: "Merchant",
//   transactionDesc: "Payment of Online Goods",
//   callbackUrl: "https://mycallbackurl.com",
// });
  return (
    <div className="container" >
    <div className="card">
      <img className='title' src={MPESAImage} alt='mpesa-logo'/> 
      <input value={mpesanumber} className="input" placeholder="Enter your phone number" />
      <button onClick={lipaNaMpesa} className="submit">Subscribe</button>
    </div> 
    </div>
  );
}

export default App;
